@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/brains/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/brains/src/scss/utility/_mixin.scss";


.page404 {

  &__content {
    text-align: center;
    padding-top: 150px;
    padding-bottom: 200px;

    &--desc {
      line-height: 2.3;
      margin-bottom: 40px;
    }
    .cmn_btn {
      margin: 0 auto;
    }
  }
  @include max-screen(767px) {

    &__content {
      padding-top: 65px;
      padding-bottom: 120px;

      &--desc {
        line-height: 2;
        margin-bottom: 25px;
      }
    }
  }
}
